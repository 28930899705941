<script lang="ts">
  import logo from "$lib/images/BBIA.png";
  import Loader from "$lib/components/Loader.svelte";
  import { mounted } from "$lib/stores";
  import { page } from "$app/stores";
  import { goto } from "$app/navigation";

  const minTime = 1000;

  mounted.subscribe((value) => {
    if (value) {
      console.log("mounted");
      const nextPage = $page.url.toString().split("redirect=")[1];
      const elapsed = new Date().getTime() - timestamp;
      const time = elapsed < minTime ? minTime - elapsed : 0;
      setTimeout(() => {
        // check if nextPage is a valid route
        const nav = goto(nextPage || "/workspaces");
        nav.catch((error) => {
          console.error(error);
          goto("/workspaces");
        });
      }, time);
    }
  });

  $: message = "Redirecting";

  const timestamp = new Date().getTime();

  setInterval(() => {
    message = message === "Redirecting..." ? "Redirecting" : message + ".";
  }, 300);
</script>

<main>
  <div class="w-full h-full">
    <div class="flex items-center justify-center">
      <img src={logo} alt="logo" class="w-32" />
      <h1 class="text-6xl font-bold text-center mb-2">BBIA</h1>
    </div>
    <div class="flex items-center justify-center">
      <Loader {message} />
    </div>
  </div>
</main>
